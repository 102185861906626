import React from 'react';
import moment from 'moment';
import { trackEvent } from 'utils/Mixpanel';
import {
  Layout,
  LegacyCard,
  TextContainer,
  Badge,
  LegacyStack,
  Banner,
  ProgressBar,
  Thumbnail,
  Button,
  Text,
  BlockStack,
  InlineStack,
} from "@shopify/polaris";
import PaymentIcon from 'components/PaymentIcon';
import billingPlanDetails, { formatCurrency } from 'utils/billingPlanDetails';
import junipIcon from 'assets/images/junipIcon.svg';

export default function ActivePlan({
  planActive,
  activeBillingPlan,
  activeBillingSubscription,
  setShowCancelModal,
  setStripeModalData,
  cancelLoading,
  chooseLoading,
  stripeRecord,
  twoPointOh = false,
  setShowCostBreakdown,
}) {
  if (!planActive) {
    if (stripeRecord) {
      return (
        <Layout.Section variant="fullWidth">
          <LegacyCard
            title="Payment method"
            actions={[{
              content: 'Edit',
              disabled: chooseLoading || cancelLoading,
              onAction: () => { setStripeModalData({ action: 'edit' }); trackEvent('Billing settings - Edit payment method'); }
            }]}
            sectioned>
            <div className="d-flex">
              <PaymentIcon paymentType={stripeRecord.card_brand} />
              <TextContainer spacing="tight">
                <div>
                  <span style={{ textTransform: 'capitalize' }}>
                    {stripeRecord.card_brand !== 'unkown' ? stripeRecord.card_brand : 'Card'}{' '}
                  </span>
                  •••• {stripeRecord.card_last4}
                </div>
                <div>
                  Billing email: {stripeRecord.email}
                </div>
              </TextContainer>
            </div>
          </LegacyCard>
        </Layout.Section>
      );
    } else {
      return (null);
    }
  }

  const {
    name,
    price,
  } = billingPlanDetails(activeBillingPlan, twoPointOh) || {};

  const billingItems = activeBillingSubscription?.items || [];
  const billingAddons = activeBillingPlan?.addons || [];
  const basePrice = activeBillingPlan?.base_price || {};

  const totalCost = formatCurrency({
    cents: basePrice?.cents
      + (billingItems.reduce((acc, item) => {
        const addon = billingAddons.find(addon => addon.id === item.billing_addon.id);
        return acc + addon?.price_cents;
      }, 0)),
    currency_iso: basePrice?.currency_iso,
  }) || "$0";

  const cancel = () => {
    setShowCancelModal(true);
  };

  return (
    <Layout.Section variant="fullWidth">
      <LegacyCard
        title={
          activeBillingSubscription.auto_renew === false ?
            <LegacyStack alignment="center" distribution="equalSpacing">
              <Text variant="headingMd" as="h2">Current plan</Text>
              <Badge tone="warning">Cancelled</Badge>
            </LegacyStack>
            :
            <Text variant="headingMd" as="h2">Current plan</Text>
        }>
        <LegacyCard.Section>
          <TextContainer>
            {activeBillingSubscription.auto_renew === false &&
              <Banner tone="warning">
                Your subscription will end on {moment(activeBillingSubscription.next_billing_date).format('MMMM Do, YYYY')}.
              </Banner>
            }
            <div>
              <LegacyStack>
                <Thumbnail source={junipIcon} size="small" />
                <BlockStack>
                  <Text variant="headingMd" as="h2">{name}</Text>
                  <Text variant="bodyMd" as="span" tone="subdued">
                    {totalCost} USD billed monthly{activeBillingSubscription.auto_renew !== false &&
                      <>
                        . Renews on {moment(activeBillingSubscription.next_billing_date).format('MMMM Do, YYYY')}
                      </>
                    }
                  </Text>
                  {twoPointOh && billingItems.length > 0 && name !== "Free" &&
                    <InlineStack gap="100">
                      <Text variant="subdued">Includes {price} base price + {billingItems.length} add-on{billingItems.length > 1 ? "s" : ""}.</Text>
                      <Button variant="plain" onClick={() => setShowCostBreakdown(true)}>View cost breakdown</Button>
                    </InlineStack>
                  }
                </BlockStack>
              </LegacyStack>
            </div>
          </TextContainer>
        </LegacyCard.Section>
        {activeBillingPlan?.usage_limit && !twoPointOh &&
          <LegacyCard.Section
            title="Usage"
          >
            <LegacyStack vertical>
              <p>You have used {activeBillingSubscription?.usage} of your {activeBillingSubscription?.usage_limit} order monthly usage limit. It will reset at the end of your billing cycle.</p>
              {activeBillingSubscription?.usage &&
                <ProgressBar progress={(activeBillingSubscription?.usage / activeBillingSubscription?.usage_limit) * 100} size="small" tone="primary" />
              }
            </LegacyStack>
          </LegacyCard.Section>
        }
        {stripeRecord &&
          <LegacyCard.Section
            title="Payment method"
            actions={[{
              content: 'Update payment method',
              disabled: chooseLoading || cancelLoading,
              onAction: () => { setStripeModalData({ action: 'edit' }); trackEvent('Billing settings - Edit payment method'); }
            }]}
          >
            <div className="d-flex align-items-center">
              <PaymentIcon paymentType={stripeRecord.card_brand} />
              <div>
                <div>
                  <span style={{ textTransform: 'capitalize' }}>
                    {stripeRecord.card_brand !== 'unkown' ? stripeRecord.card_brand : 'Card'}{' '}
                  </span>
                  •••• {stripeRecord.card_last4}
                </div>
                <div>
                  Billing email: {stripeRecord.email}
                </div>
              </div>
            </div>
          </LegacyCard.Section>
        }
        {activeBillingSubscription.auto_renew !== false && activeBillingSubscription.provider !== 'junip' &&
          <LegacyCard.Section subdued>
            <LegacyStack distribution="trailing">
              <Button
                content={`${cancelLoading ? 'Cancelling' : 'Cancel Plan'}`}
                disabled={chooseLoading || cancelLoading}
                loading={cancelLoading}
                onClick={cancel}


                tone="critical">Cancel Plan</Button>
            </LegacyStack>
          </LegacyCard.Section>
        }
      </LegacyCard>
    </Layout.Section>
  );
}
